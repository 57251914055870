<template>
	<div class="animated fadeIn">
		<template v-if="isAdminOrCorporate">
			<b-alert
				show
				variant="secondary">
				<div class="row">
					<div class="col">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role: searchRole}"
							class="mb-3"
							q="label"
							return-key="username" />
						<div class="row">
							<div class="col">
								<b-button
									:disabled="loadingSummary"
									variant="primary"
									class="btn mx-1"
									@click="changeDistributor()">
									{{ translate('search') }}
								</b-button>
								<b-button
									:disabled="loadingSummary"
									variant="secondary"
									class="btn mx-1"
									@click="clearDistributor()">
									{{ translate('clear') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-alert>
		</template>
		<b-row class="justify-content-end">
			<b-col class="col-12">
				<div
					v-if="!loadingSummary"
					:class="['xs'].includes(windowWidth) ? 'flex-column' : ''"
					class="d-flex h-100 justify-contents-end">
					<div
						v-if="searchedIsDistributorOrAffiliate"
						:class="['xs'].includes(windowWidth) || !(showPoints && searchedIsDistributor) ? '' : 'px-4 border-right'"
						class="text-right h3 ml-auto">
						<template v-if="showBaseBalance && baseBalance[walletType]">
							<span class="h3 m-0">
								{{ translate('total_balance') }}:
								<span
									v-b-tooltip.hover.html
									:title="baseBalance[walletType]"
									class="pointer btn-link">
									{{ balance[walletType] }}
								</span>
							</span>
						</template>
						<span
							v-else
							class="h3">
							{{ translate('total_balance') }}: {{ balance[walletType] }}
						</span>
						<br>
						<template v-if="showBaseBalance && baseRestrictedBalance[walletType]">
							<span class="h5 m-0">
								{{ translate('restricted_balance') }}:
								<span
									v-b-tooltip.hover.html
									:title="baseRestrictedBalance[walletType]"
									class="pointer btn-link">
									{{ restrictedBalance[walletType] }}
								</span>
							</span>
						</template>
						<span
							v-else
							class="h5">
							{{ translate('restricted_balance') }}: {{ restrictedBalance[walletType] }}
						</span>
						<span
							v-b-tooltip.hover.html
							:title="translate('restricted_balance_tooltip')"
							class="text-small h5">
							<i class="fas fa-info-circle" />
						</span>
						<div
							v-if="balance[migratedWalletType]"
							style="margin-bottom: -5px">
							<span
								v-if="balance[migratedWalletType]"
								class="h6 text-muted">
								{{ translate('migrated_balance') }}: {{ balance[migratedWalletType] }}
							</span>
							<span
								v-b-tooltip.hover.html
								:title="translate('migrated_balance_tooltip')"
								class="text-small h6 text-muted">
								<i
									class="fas fa-info-circle"
									style="font-size: 13px" />
							</span>
						</div>
						<div
							v-if="balance[migratedIPayoutWalletType]"
							style="margin-bottom: -5px">
							<span
								v-if="balance[migratedIPayoutWalletType]"
								class="h6 text-muted">
								{{ translate('migrated_i_payout_balance') }}: {{ balance[migratedIPayoutWalletType] }}
							</span>
							<span
								v-b-tooltip.hover.html
								:title="translate('migrated_balance_tooltip')"
								class="text-small h6 text-muted">
								<i
									class="fas fa-info-circle"
									style="font-size: 13px" />
							</span>
						</div>
						<div
							v-if="balance[migratedCommissionWalletType]"
							style="margin-bottom: -5px">
							<span
								v-if="balance[migratedCommissionWalletType]"
								class="h6 text-muted">
								{{ translate('migrated_commission_balance') }}: {{ balance[migratedCommissionWalletType] }}
							</span>
							<span
								v-b-tooltip.hover.html
								:title="translate('migrated_balance_tooltip')"
								class="text-small h6 text-muted">
								<i
									class="fas fa-info-circle"
									style="font-size: 13px" />
							</span>
						</div>
					</div>
					<div
						v-if="showPoints && searchedIsDistributor"
						:class="(['xs'].includes(windowWidth) ? 'ml-auto' : 'px-4') + (searchedIsDistributorOrAffiliate ? '' : ' ml-auto')"
						class="text-right h3">
						<span class="h3">
							{{ translate('points') }}: {{ points.balance }}
						</span>
						<br>
						<template v-if="points.show_base_balance">
							<span class="h5 m-0">
								<span
									v-b-tooltip.hover.html
									:title="points.base_balance.balance_currency"
									class="pointer btn-link">
									{{ points.balance_currency }}
								</span>
							</span>
						</template>
						<span
							v-else
							class="h5">
							{{ points.balance_currency }}
						</span>
					</div>
				</div>
			</b-col>
		</b-row>
		<hr>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<template v-for="(tabInfo, index) in tabs">
						<b-tab :key="index">
							<template slot="title">
								<router-link
									v-if="displayTab(tabInfo)"
									:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
									:to="{ name: tabInfo.name, query: { ...$route.query } }"
									class="list-group-item btn text-left">
									{{ translate(tabInfo.translate_key) }}
								</router-link>
							</template>
							<template
								v-if="tabIndex === index"
								class="p-0">
								<router-view />
							</template>
						</b-tab>
					</template>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import {
	admin, distributor, customer, affiliate,
} from '@/settings/Roles';
import {
	Grids, Wallets as WalletMessages,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import WindowSizes from '@/mixins/WindowSizes';
import Wallets from '@/util/Wallets';
import {
	WALLET_TYPES as walletTypes,
	VALID_TAB_FILTERS as validTabFilters,
	HIDE_WITHDRAWAL_TO_COUNTRIES as hiddenWithdrawalCountries,
} from '@/settings/Wallets';
import { SEARCH_USERS } from '@/config/endpoint';
import { PAGINATION } from '@/settings/RequestReply';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Transactions',
	messages: [Grids, WalletMessages],
	mixins: [WindowSizes, FiltersParams],
	data() {
		return {
			admin,
			distributor,
			affiliate,
			summary: new Wallets(),
			pointsSummary: new Wallets(),
			endpoint,
			tabIndex: 0,
			tabs: [],
			searchRole: [distributor, customer, affiliate].join(','),
		};
	},
	computed: {
		isDistributor() {
			return this.$user.details().type === distributor;
		},
		isDistributorOrAffiliate() {
			return this.$user.details().type === distributor || this.$user.details().type === affiliate;
		},
		isAffiliate() {
			return this.$user.details().type === affiliate;
		},
		isCustomer() {
			return this.$user.details().type === customer;
		},
		loadingSummary() {
			return !!this.summary.data.loading || !!this.pointsSummary.data.loading;
		},
		balance() {
			try {
				const { total_balance: totalBalance } = this.summary.data.response.data.response;
				return totalBalance;
			} catch (error) {
				return [];
			}
		},
		restrictedBalance() {
			try {
				const { restricted_balance: restrictedBalance } = this.summary.data.response.data.response;
				return restrictedBalance;
			} catch (error) {
				return [];
			}
		},
		baseBalance() {
			try {
				const { base_total_balance: baseTotalBalance } = this.summary.data.response.data.response;
				return baseTotalBalance;
			} catch (error) {
				return [];
			}
		},
		baseRestrictedBalance() {
			try {
				const { base_restricted_balance: baseRestrictedBalance } = this.summary.data.response.data.response;
				return baseRestrictedBalance;
			} catch (error) {
				return [];
			}
		},
		showBaseBalance() {
			try {
				const { show_base_balance: showBaseBalance } = this.summary.data.response.data.response;
				return showBaseBalance;
			} catch (error) {
				return [];
			}
		},
		points() {
			try {
				let points = {};
				if (Object.keys(this.pointsSummary.data.response.data.response.balances).length) {
					points = this.pointsSummary.data.response.data.response.balances.default;
				}
				return points;
			} catch (error) {
				return {};
			}
		},
		walletType() {
			return walletTypes.commission;
		},
		migratedWalletType() {
			return walletTypes.migrated;
		},
		migratedIPayoutWalletType() {
			return walletTypes.migrated_i_payout;
		},
		migratedCommissionWalletType() {
			return walletTypes.migrated_commission;
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
		showPoints() {
			return this.isAdminOrCorporate || this.isDistributor || this.isAffiliate || this.isCustomer;
		},
		showPointsNoAdmin() {
			return this.isDistributor || this.isAffiliate || this.isCustomer;
		},
		isSearchingUser() {
			return this.$route.query.username !== undefined;
		},
		searchedIsDistributor() {
			return Object.keys(this.points).length;
		},
		searchedIsDistributorOrAffiliate() {
			return Object.keys(this.balance).length;
		},
	},
	beforeRouteUpdate: (to, from, next) => {
		if (Object.keys(validTabFilters).includes(to.name) && from.query.sortCol !== undefined) {
			if (!validTabFilters[to.name].includes(from.query.sortCol)) {
				delete to.query.sortCol;
				delete to.query.sortDir;
			}
		}
		next();
	},
	created() {
		this.tabs = [
			{
				name: 'WalletsTransactions',
				permission_section: 'wallet_transactions',
				permission_action: 'view',
				roles: [...admin, distributor, affiliate],
				translate_key: 'transactions',
			},
			{
				name: 'WalletsWithdrawal',
				permission_section: 'wallet_transactions',
				permission_action: 'view',
				roles: [distributor, affiliate],
				translate_key: 'withdraw',
			},
			{
				name: 'WalletsTransfer',
				permission_section: 'wallet_transactions',
				permission_action: 'transfer',
				roles: [distributor],
				translate_key: 'transfer',
			},
			{
				name: 'WalletsBalances',
				permission_section: 'wallet_transactions',
				permission_action: 'view',
				roles: admin,
				translate_key: 'balances',
			},
			{
				name: 'WalletsSettings',
				permission_section: 'wallet_transactions',
				permission_action: 'view',
				roles: [distributor, affiliate],
				translate_key: 'settings',
			},
		];

		if (this.showPoints) {
			this.tabs.splice(3, 0,
				{
					name: 'WalletsPoints',
					permission_section: 'points',
					permission_action: 'view',
					roles: [...admin, distributor, affiliate, customer],
					translate_key: 'points',
				},
				{
					name: 'WalletsPointsSummaries',
					permission_section: 'points',
					permission_action: 'view',
					roles: [...admin, distributor, affiliate, customer],
					translate_key: 'points_summaries',
				},
				{
					name: 'WalletsPointsTransfer',
					permission_section: 'points',
					permission_action: 'transfer',
					roles: [distributor],
					translate_key: 'points_transfer',
				});
		}

		if (!this.isAdminOrCorporate && hiddenWithdrawalCountries.includes(this.$user.details().country.iso_code_2)) {
			this.tabs = this.tabs.filter((tab) => !['WalletsWithdrawal', 'WalletPointsTransfer'].includes(tab.name));
		}

		if (this.showPointsNoAdmin) this.pointsSummary.getPointsBalance();

		if (this.isDistributorOrAffiliate) this.summary.getBalance();

		if (this.isAdminOrCorporate && this.isSearchingUser) {
			this.pointsSummary.getUserPointsBalance();
			this.summary.getUserBalance();
		}

		if (this.$route.name === 'WalletsTransactions') {
			this.searchRole = [distributor, customer, affiliate].join(',');
		} else if (this.$route.name === 'WalletsBalances') {
			this.searchRole = [distributor, affiliate].join(',');
		}

		this.noFilterable.push('username');
	},
	methods: {
		displayTab(tabInfo) {
			if (tabInfo.name === 'WalletsWithdrawal' && this.$user.details().hide_wallet_withdrawal) {
				return false;
			}
			if (tabInfo.name === 'WalletsTransfer' && this.$user.details().hide_wallet_transfer) {
				return false;
			}
			const hasPermission = this.$can(tabInfo.permission_section, tabInfo.permission_action);
			const canSeeTab = tabInfo.roles.includes(this.$user.details().type);
			return canSeeTab && hasPermission;
		},
		changeDistributor() {
			const { name, params, query } = this.$route;
			Object.keys(this.filters).forEach((item) => {
				if (this.filters[item] === null) {
					delete this.filters[item];
				}
			});
			const options = { ...query, ...this.filters };
			this.$router.push({ name, params, query: options });
		},
		clearDistributor() {
			const { query, name, params } = this.$route;
			if (typeof query[PAGINATION] === 'undefined') {
				query[PAGINATION] = 1;
			}
			delete query.username;
			this.$router.replace('?');
			this.$router.replace({ name, query, params });
		},
	},
};
</script>
